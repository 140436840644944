import React, { useState,useEffect } from "react";
import { toast } from 'react-toastify'
import { MultiSelect } from "react-multi-select-component";
import Multiselect from 'multiselect-react-dropdown';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useGetAllStudent ,useCreateClass} from "../../utils/api/userApi";
import { grades } from "../../utils/constants";
// import { MultiSelect } from 'primereact/multiselect';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
        

const AddNewClass = ({addClassOpen, handleAddClassClose}) => {
const navigate=useNavigate();
// State to store class details
  const { data: student }=useGetAllStudent()
  const [selectedStudents, setSelectedStudents] = useState([]);
  const logData=JSON.parse(localStorage.getItem('UserData'))
  
  // console.log("🚀 ~ AddClass ~ student:", student)
  
  const [className, setClassName] = useState("");
  const [grade, setGrade] = useState("");
  const [open, setOpen] = useState(true)
  
  
  
  const registeredStudents =student&& student?.students?.map((student) => ({
    label: student?.name, // This will be shown in the dropdown
    value: student?.email, // The value to be used in the form
  }))|| [];
  
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "className") setClassName(value);
  };

  const handleGradeChange=(e)=>{
    setGrade(e.target.value)
  }
  const {mutate:createClass, onSuccess}=useCreateClass()
  
  
  const CreateClassroom= async ()=>{
    const selectedEmails = selectedStudents?.map((student) => student?.value);
    if(!className){
      return toast.error("Class Name is required")
     }
    if(selectedEmails?.length===0){
     return toast.error("Please select at least one student")
    }
    if(!grade){
      return toast.error("Please select Grade")
     }
    const data={
      name:className,
      teacher_id:logData?.userId,
      emails:selectedEmails,
      grade:grade
    }
    console.log("🚀 ~ CreateClassroom ~ data:", data)
    createClass(data, {
      onSuccess: (response) => {
          toast.success(response?.data?.message );
          handleAddClassClose()
      },
      onError: (error) => {
          toast.error(error.message);
      }
  })
    
  }
  if(!addClassOpen ) return null
  
  return (
    <div class="  fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
  
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">   
        <div class="relative transform overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div className="h-[85vh] max-w-xl mx-auto p-4">
    
      <h2 className="flex justify-start border-b border-gray-200 text-2xl font-bold mb-4  text-center">Create New Class</h2>
      <div  className="flex flex-col justify-between ">
        
        <div className="flex flex-col gap-2">
          <div className="w-full">
            <label
              className=" text-lg flex justify-start font-semibold mb-2"
              htmlFor="className"
            >
              Class Name
            </label>
            <input
              type="text"
              id="className"
              name="className"
              value={className}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500"
              placeholder="Enter class name"
              required
            />
          </div>

          <label className="flex justify-start text-lg font-semibold mb-2">
          Select Students
        </label>
        
        <MultiSelect
          options={registeredStudents }
          value={selectedStudents}
          onChange={setSelectedStudents}
          labelledBy="Select"
          className="z-[999]"
          
        />
          <label className="flex justify-start text-lg font-semibold mb-2">
          Select Grade
        </label>
        <select 
        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500"
        onChange={handleGradeChange}
        value={grade}
        >
          <option value=''>Select Grade </option>
          {grades?.map((grade,index)=>(
          <option value={grade?.value}>{grade?.label}</option>
          ))}
        </select>
        </div>
        <div className="h-[27vh]"></div>
     
        
        <div className="text-center flex gap-2 justify-end w-full">
        <button
            onClick={handleAddClassClose}
            className="px-6 py-1 border border-gray-500 text-[#000] rounded-lg  font-semibold"
          >
            Cancel
          </button>
          <button
            
            onClick={CreateClassroom}
            className="px-6 py-1 bg-blue-500 text-white rounded-lg hover:bg-blue-600 font-semibold"
          >
            Create Class
          </button>
        </div>
      </div>
    </div>
          
        </div>
      </div>
    </div>
  </div>
 
    
  );
};

export default AddNewClass;
