import React, { useState } from "react";
import axios from "axios";
import AIButton from "../../../components/AiButton";
import { toast } from "react-toastify";
import {
  useGetSubmissions,
  useBulkApproveEvaluation,
  useGetTeacherAssignment,
} from "../../../utils/api/userApi";
import { useLocation, useNavigate } from "react-router-dom";

import TeacherAssigDetailTable from "./teacherAssignmentDetailTable";
const TeacherAssignmentDetails = () => {
  const navigate =useNavigate()
  const teacherId=JSON.parse(localStorage.getItem("UserData"))
  console.log("teacherId:", teacherId)
  const { state} = useLocation();
  const AssigID=state?.id || null;
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [selectedSubmissions, setSelectedSubmissions] = useState([]);
console.log("🚀 ~ TeacherAssignmentDetails ~ selectedSubmissions:", selectedSubmissions)
  
    const { data: submissions } = useGetSubmissions(AssigID );
    const {mutate:bulkApproveEvaluation}=useBulkApproveEvaluation()

    console.log("🚀 ~ TeacherAssignmentDetails ~ submissions:", submissions)
    const handleSearch = (e) => {
      const value = e.target.value;
      setSearchValue(value);
    };
    const totalPages = submissions?.pagination?.totalPages || 1;
    
    const handlePageChange = (newPage) => {
      setPage(newPage);
    };
    const handleEvaluationNavigate=(submission)=>{
      if(submission?.status !== "evaluated" && submission?.status !== "submitted"){
        toast.warning("This submission is not yet evaluated or submitted")
        return
      }
      navigate('/dashboard/assignments/evaluaton',{state:submission})
    }
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftOffset = Math.max(page - Math.floor(maxVisiblePages / 2), 1);
      const rightOffset = Math.min(
        leftOffset + maxVisiblePages - 1,
        totalPages
      );

      if (leftOffset > 1) pageNumbers.push(1, "...");
      for (let i = leftOffset; i <= rightOffset; i++) {
        pageNumbers.push(i);
      }
      if (rightOffset < totalPages) pageNumbers.push("...", totalPages);
    }

    return pageNumbers;
  };
  const handleBulkApprove=()=>{
    if (selectedSubmissions.length === 0) {
      toast.warning('Please select at least one submission');
      return;
    }

    const data={
      teacherID:teacherId?.userId,
      studentAssignmentIds:selectedSubmissions
    }
    bulkApproveEvaluation(data,{
      onSuccess:()=>{
        toast.success('Bulk Approved Successfully')
        setSelectedSubmissions([]); // Clear selections after successful approval

      }, 
   onError:(error)=>{
    toast.error(error?.response?.data?.message)
   }
    })
  }
  return (
    <div className="p-4">
      <div className="flex  justify-end items-center gap-2 pb-4">
        <button 
        onClick={handleBulkApprove}
        disabled={selectedSubmissions.length === 0}
        className={`px-4 py-1.5 rounded-md ${
          selectedSubmissions.length > 0 
            ? 'bg-blue-500 text-white hover:bg-blue-600' 
            : 'bg-gray-300 text-gray-500 cursor-not-allowed'
        }`}>Bulk Approve</button>
       <div class="flex justify-end items-center gap-2  bg-white ">
              <label for="table-search" class="sr-only">
                Search
              </label>
              <div class="relative ">
                <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    class="w-4 h-4 text-gray-500 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  value={searchValue}
                  onChange={handleSearch}
                  type="text"
                  id="table-search"
                  class="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                  placeholder="Search By Name"
                />
              </div>
            </div>
            </div>

      <TeacherAssigDetailTable
        handlePageChange={handlePageChange}
        totalPages={totalPages}
        page={page}
        submissions={submissions}
        getPageNumbers={getPageNumbers}
        handleEvaluationNavigate={handleEvaluationNavigate}
        selectedSubmissions={selectedSubmissions}
        setSelectedSubmissions={setSelectedSubmissions}
      />
    </div>
  );
};

export default TeacherAssignmentDetails;
