import React ,{useState,useContext} from 'react'
import adminpic from "../../assets/image.png";
import dropdownIcon from "../../assets/Dropdown.svg";
import { NavLink ,useNavigate} from 'react-router-dom';
import searchIconSrc from "../../assets/searchIcon.svg";
import NotificationIcon from "../../assets/notification.svg";
import { NotificationContext } from '../context/notificationContext';

const Header = () => {
  const navigate=useNavigate()
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectMenue, setelectMenue] = useState("Admin Management");
  const [searchValue,setSearchValue]=useState('')
  const UserData =JSON.parse(localStorage.getItem('UserData'))
  console.log("🚀 ~ Header ~ UserData:", UserData)
  const {handleOpenNotification,notifications}=useContext(NotificationContext)



  const handleLogout = () => {
    localStorage.clear("UserData");
    localStorage.clear("Token")
    navigate("/");
  };
  const notificationCount=notifications?.length
  return (
    <div className="flex justify-between item-center px-5 shadow-md border-b">
            
          <nav className="flex justify-end w-full items-center gap-12 lg:gap-6 p-3">
            <div className='relative'>
              <div onClick={handleOpenNotification} className='cursor-pointer rounded-full p-2 bg-gray-200 w-11 h-11 flex justify-center items-center'>
                <img src={NotificationIcon} alt='notification'/>
              </div>
              {notificationCount > 0 && (
          <span className="absolute top-1 right-1 inline-flex items-center justify-center px-2 py-1  text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
            {notificationCount }
          </span>
        )}
            </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <img
              style={{ height: "45px", borderRadius: "50%" }}
              src={ adminpic}
              alt="Admin"
            />
            <div>
              <p className="text-xl font-bold text-black-800">
                {UserData?.name}
              </p>
              <p style={{ color: "#909090" }} className="text-sm font-normal">
                {UserData?.email}
              </p>
            </div>
          </div>

          <div
            style={{
              backgroundColor: "#EEEEEE",
              borderRadius: "50%",
              padding: "10px",
              paddingTop: "14px",
              position: "relative",
            }}
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <img src={dropdownIcon} alt="Dropdown" className="max-w-fit" />

            {dropdownOpen && (
              <div className="absolute right-0 top-10 mt-2 w-64 bg-white rounded-lg border border-[#F1F1F1] shadow-lg z-[1000]">
                <ul className="py-1 px-4">
                
                    {/* <li
                      className="border-b border-[#F1F1F1] "
                      onClick={() => setelectMenue("Admin Management")}
                    >
                      <NavLink
                        to="/admin-management"
                        className={`${
                          selectMenue === "Admin Management"
                            ? "text-[#030319] font-[600]"
                            : "text-[#8F92A1]"
                        } block  py-2 text-sm  hover:text-[#030319] transition hover:font-semibold`}
                      >
                        Admin Management
                      </NavLink>
                    </li> */}
                
                  
                    <>
                      {/* <li
                        className="border-b border-[#F1F1F1] "
                        onClick={() => setelectMenue("Change Email")}
                      >
                        <span
                          // onClick={() => setEmailModalOpen(true)}
                          className={`${
                            selectMenue === "Change Email"
                              ? "text-[#030319] font-[600]"
                              : "text-[#8F92A1]"
                          } block  py-2 text-sm   hover:text-[#030319] transition hover:font-semibold`}
                        >
                          Change Email
                        </span>
                      </li> */}
                      <li
                        className="border-b border-[#F1F1F1] "
                        onClick={() => setelectMenue("Change Password")}
                      >
                        <span
                          // onClick={() => setPasswordModalOpen(true)}
                          className={`${
                            selectMenue === "Change Password"
                              ? "text-[#030319] font-[600]"
                              : "text-[#8F92A1]"
                          } block  py-2 text-sm   hover:text-[#030319] transition hover:font-semibold`}
                        >
                          Change Password
                        </span>
                      </li>
                      <li 
                      onClick={() => setelectMenue("Logout")}
                      >
                        <span
                          onClick={handleLogout}
                          className={`${
                            selectMenue === "Logout"
                              ? "text-[#030319] font-[600]"
                              : "text-[#8F92A1]"
                          } block  py-2 text-sm   hover:text-[#030319] transition hover:font-semibold`}
                        >
                          Logout
                        </span>
                      </li>
                    </>
                
                </ul>
              </div>
            )}
          </div>
        </nav>
        {/* <Logout handleLogoutClose={handleLogoutClose} logoutOpen={logoutOpen}/> */}
         </div>
  )
}

export default Header
