import React, { useCallback, useEffect } from 'react'
import { useLocation ,useNavigate} from 'react-router-dom';
import AssignmentTimer from './AssignmentTimer';
import AssignmentEditor from './AssignmentEditor';
import { toast } from "react-toastify";
import Loader from '../../../components/sharedUi/loader';
import AIButton from '../../../components/AiButton';
import { useGetAssignment,useSubmitEssay,useContinousSaing ,useGetSaveContent} from "../../../utils/api/userApi";
import { useTimerContext } from "../../../components/context/timerContext";

const AssignmentEditing = () => {
 const {state}=useLocation()
 const navigate=useNavigate()
 const data=JSON.parse(localStorage.getItem('UserData'))
 const {essayContent,setEssayContent,handleEssayContent}=useTimerContext() 
 const {data:getSaveContent ,refetch}=useGetSaveContent(state?.assignment?.studentAssignmentId,
    {
        enabled: !!state?.assignment?.studentAssignmentId, // Enable fetch when ID exists
        refetchOnWindowFocus: false,
        refetchOnMount: true, // Fetch when component mounts
        cacheTime: 0, // Don't cache the result
      }
 )
 console.log("🚀 ~ AssignmentEditing ~ essayContent:", getSaveContent)


 const {mutate:submitEssay ,isPending}=useSubmitEssay()
 const {mutate:continousSaing ,isPending:isContinousSaingPending}=useContinousSaing()
 
const handleTimeUp = () => {
    // Auto submit logic here
    toast.error('Time is up!')
    const data={
      essayContent:essayContent,
      student_assignment_id:state?.assignment?.studentAssignmentId
    }
    submitEssay(data,{
      onSuccess: (response) => {
        console.log("response:", response);
        // handleAssignmentClose();
        toast.success(response?.message || "Essay submitted successfully!");
        navigate('/dashboard/assignment')
      },
      onError: (error) => {
        toast.error("Submission failed: " + error.message);
      },
    })
  };
  const autoSaveDraft = useCallback(() => {
    const data = {
      "student_assignment_id": state?.assignment?.studentAssignmentId,
      "content": essayContent
    };
    continousSaing(data, {
      onSuccess: (response) => {
        console.log("response:", response);
        // toast.success(response?.message || "Content auto-saved!");
      },
      onError: (error) => {
        toast.error("Auto-save failed: " + error.message);
      },
    });
  }, [state?.assignment?.studentAssignmentId,essayContent]);
  
  useEffect(() => {
    if (state?.assignment?.studentAssignmentId) {
      refetch();
    }
  }, [state?.assignment?.studentAssignmentId, refetch]);
  
  
  useEffect(() => {
    console.log('hello')
    if (getSaveContent?.content !== null) {
    console.log('hello2')

      setEssayContent(getSaveContent?.content);
    }else{
    
    console.log('hello3')

    }
  }, [getSaveContent,setEssayContent]);


   
  const handleSubmission = async () => {
    if (!essayContent) return;
  const data={
    essayContent:essayContent,
    student_assignment_id:state?.assignment?.studentAssignmentId
  }
  submitEssay(data,{
    onSuccess: (response) => {
      console.log("response:", response);
      navigate('/dashboard/assignment')
      setEssayContent('')
      toast.success(response?.message || "Essay submitted successfully!");
    },
    onError: (error) => {
      toast.error("Submission failed: " + error.message);
    },
  })
  };


  return (
    <>
    {isPending && <Loader/>}
    <div className='w-[100%] p-5'>

    <div className='flex justify-between'>
    <div className="flex flex-col">
                  <div className="flex items-center gap-2">
                    <h1 className="text-[12px] text-gray-700 font-[700]">Title :</h1>
                    <h1 className="text-[14px] font-extrabold text-[#000000]">
                      {state?.assignment?.essayTitle}
                    </h1>
                  </div>
                  <div className="flex items-center gap-2">
                    <h1 className="text-[12px] text-gray-700 font-[700]">Student Name :</h1>
                    <h1 className="text-[14px] font-extrabold text-[#000000]">
                      {data?.name}
                    </h1>
                  </div>
                  <div className="flex items-center gap-2">
                    <h1 className="text-[12px] text-gray-700 font-[700]">Class Name :</h1>
                    <h1 className="text-[14px] font-extrabold text-[#000000]">
                      {state?.assignment?.classroomName}
                    </h1>
                  </div>
                </div>
        <AssignmentTimer selecAssignment={state?.assignment} handleTimeUp={handleTimeUp} autoSaveDraft={autoSaveDraft} />
      
    </div>
    <div className=' w-full'>
        <AssignmentEditor/>
    </div>

<div className='flex justify-end'>

    <AIButton title={"Submit Assignment"} generateTitle={handleSubmission} />
</div>

    </div>

    </>
  )
}

export default AssignmentEditing
