// AIButton.js
import React from 'react';



const AIButton = ({title, generateTitle}) => {
  return (
    <>
    <button
      onClick={generateTitle}
      className={`mt-4 bg-green-500 text-white py-2 px-6 rounded-lg`}
    >
      {title}
    </button>
   
    </>
  );
};

export default AIButton;
