import React, { useState } from "react";
import DeleteModal from "../../../components/sharedUi/deleteModal";
import { toast } from "react-toastify";
import DeleteIcon from "../../../assets/delete.svg";
import ViewIcon from "../../../assets/view.svg";
import { truncateText } from "../../../utils/constants";
import AssignmentText from "./assignmentText";
import AssignmentIcon from "../../../assets/assignment.svg";

const TeacherAssgDetailTable = ({
  submissions,
  handleEvaluationNavigate,
  totalPages,
  page,
  handlePageChange,
  getPageNumbers,
  selectedSubmissions,
  setSelectedSubmissions,
}) => {
  console.log("submissions:", selectedSubmissions);

  const [viewDetails, setViewDetails] = useState();
  const [viewDetailsOpen, setViewDetailsOpen] = useState(false);

  const handleViewDetails = (e, submission) => {
    e.stopPropagation();
    setViewDetails(submission);
    setViewDetailsOpen(true);
  };

  const handleViewDetailsClose = () => {
    setViewDetailsOpen(false);
  };

  const handleCheckboxChange = (e, id) => {
    e.stopPropagation();
    const isChecked = e.target.checked;
    console.log("isChecked:11", isChecked)
    if (isChecked) {
      setSelectedSubmissions((prevSelected) => [
        ...prevSelected,
        id,
      ]);
    } else {
      setSelectedSubmissions((prevSelected) =>
        prevSelected.filter(
          (submission) =>
            submission !== id
        )
      );
    }
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    console.log("isChecked:12", isChecked)
    if (isChecked) {
      const submittedAssignmentIds =
        submissions?.submissions
          ?.filter((submission) => submission.status === "submitted"  || submission.status ===  "evaluated")
          .map((submission) => submission.student_assignment_id) || [];
      console.log("submittedAssignmentIds:", submittedAssignmentIds);
      setSelectedSubmissions(submittedAssignmentIds);
    } else {
      setSelectedSubmissions([]);
    }
  };

  const submittedAssignmentsCount =
    submissions?.submissions?.filter(
      (submission) => submission.status === "submitted"  
    )?.length || 0;

  return (
    <>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-3">
                <input
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                  checked={
                    selectedSubmissions.length === submittedAssignmentsCount &&
                    submittedAssignmentsCount > 0
                  }
                  onChange={handleSelectAll}
                  disabled={submittedAssignmentsCount === 0}
                />
              </th>
              <th scope="col" className="px-6 py-3">
                Student Name
              </th>
              <th scope="col" className="px-6 py-3">
                Assignment Status
              </th>
              <th scope="col" className="px-6 py-3">
                Submitted At
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {submissions?.message !== "No classes found for this teacher" ? (
              submissions?.submissions?.map((submission, index) => (
                <tr
                  onClick={() => handleEvaluationNavigate(submission)}
                  key={submission.studentAssignmentId}
                  className="cursor-pointer bg-white border-b hover:bg-gray-50"
                >
                  <td className="px-4 py-2" onClick={(e) => e.stopPropagation()}>
                    <input
                      type="checkbox"
                      className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 
                        ${
                          submission.status !== "submitted"
                            ? "cursor-not-allowed opacity-50"
                            : "cursor-pointer"
                        }`}
                      checked={selectedSubmissions.includes(submission?.student_assignment_id)}
                      onChange={(e) => handleCheckboxChange(e, submission?.student_assignment_id)}
                      disabled={submission.status !== "submitted"}
                    />
                  </td>
                  <td className="px-6 py-2">{submission?.student_name}</td>
                  <td className="px-6 py-2">{submission?.status}</td>
                  <td className="px-6 py-2">
                    {new Date(submission?.submitted_at).toLocaleString()}
                  </td>
                  <td className="px-6 py-2 flex gap-2">
                    {submission.status !== "submitted" && submission.status !== "evaluated"?
                    "":
                    <div className="relative group">
                      <img
                        src={AssignmentIcon}
                        alt="view"
                        className="w-3 cursor-pointer"
                        onClick={(e) => handleViewDetails(e, submission)}
                      />
                      <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        View
                      </span>
                    </div>
}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="w-full h-full flex justify-center items-center">
                <td className="px-6 py-4">
                  <h1 className="text-[12px] font-[500]">No Submission found</h1>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="flex w-full justify-end item-center p-2">
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <svg
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                  clipRule="evenodd"
                />
              </svg>
            </button>

            {getPageNumbers().map((number, index) =>
              number === "..." ? (
                <span
                  key={index}
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
                >
                  ...
                </span>
              ) : (
                <button
                  key={index}
                  onClick={() => handlePageChange(number)}
                  className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                    page === number
                      ? "z-10 bg-[#1da1f2] text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  "
                      : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  }`}
                >
                  {number}
                </button>
              )
            )}

            <button
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <svg
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </nav>
        </div>
      </div>
      <AssignmentText viewDetails={viewDetails} viewDetailsOpen={viewDetailsOpen} handleViewDetailsClose={handleViewDetailsClose}/>
    </>
  );
};

export default TeacherAssgDetailTable;
