import React, { useState } from "react";
import DeleteModal from "../../../components/sharedUi/deleteModal";
import { toast } from "react-toastify";
import DeleteIcon from "../../../assets/delete.svg";
import ViewIcon from "../../../assets/view.svg";
import { truncateText } from "../../../utils/constants";
import SortingIcon from "../../../assets/sortingIcon.svg";
import { useNavigate } from "react-router-dom";

const TeacherSubTable = ({
  teacherAssignment,
  handleNavigate,
  totalPages,
  page,
  handlePageChange,
  getPageNumbers,
}) => {

    const navigate=useNavigate()
  const [sortConfig, setSortConfig] = useState({
    key: "title",
    direction: "ascending",
  });

  const sortedAssignments = React.useMemo(() => {
    let sortableAssignments = [...(teacherAssignment?.submissions || [])];
    if (sortConfig !== null) {
      sortableAssignments.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableAssignments;
  }, [teacherAssignment, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleNavigateSubmission=(submission)=>{
    console.log("submission:", submission)
    navigate('/dashboard/student-submissions',{state:submission})
  }

  return (
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead class=" text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr className=" w-full ">
          <th className="  px-6 py-3">
              <div className="flex gap-2">
                Student Name{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("name")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th  className="  px-6 py-3">
              <div className="flex gap-2">
                Title{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("title")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th  className=" px-6 py-3">
              <div className="flex gap-2">
                Class Name{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("classroom_name")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th  className="  px-6 py-3">
              <div className="flex gap-2">
                Due Date{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("due_date")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th  className="  px-6 py-3">
              <div className="flex gap-2">
                Status{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("status")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th  className="   px-6 py-3">
              <div className="flex gap-2">
                Ai Score{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("score")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            {/* <th scope="col" className="px-6 py-3">
              Action
            </th> */}
          </tr>
        </thead>
        <tbody>
          {teacherAssignment?.message !==
          "No Assignments found for this teacher" ? (
            sortedAssignments?.map((assignment, index) => (
              <tr
              onClick={()=>handleNavigateSubmission(assignment)}
                key={index}
                class="bg-white border-b cursor-pointer  hover:bg-gray-50 "
              >
                 <td class="px-6 py-2">
                  {assignment?.student_name}
                </td>
                <td class="px-6 py-2">
                  {assignment?.essay_title}
                </td>
                <th class="px-6 py-2">{assignment?.classroom_name}</th>
                <td class="px-6 py-2">
                  {new Date(assignment?.due_date).toLocaleString()}
                </td>

                <td class="px-6 py-2">{assignment?.status}</td>
                <td class="px-6 py-2">
                    {assignment?.ai_score}
                </td>
                {/* <td className="px-6 py-2 flex gap-2"> */}
                  {/* <div className="relative group">
                    <img
                      src={ViewIcon}
                      alt="view"
                      onClick={() => handleNavigate(assignment)}
                      className="w-3 cursor-pointer"
                    />
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      View
                    </span>
                  </div> */}
                  {/* <div className="relative group">
                  <img onClick={()=>handleEditClassOpen(classItem)} src={EditIcon} alt='Edit' className="w-3 cursor-pointer"/>
                  <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    Edit
                  </span>
                </div> */}
                  {/* <div className="relative group">
                    <img
                      src={DeleteIcon}
                      alt="Delete"
                      className="w-3 cursor-pointer"
                    />
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      Delete
                    </span>
                  </div> */}
                {/* </td> */}
              </tr>
            ))
          ) : (
            <tr className="w-full h-full  flex justify-center items-center">
              <td class="px-6 py-4">
                <h1 className="text-[12px] font-[500]">No Classes found </h1>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="flex w-full justify-end item-center p-2">
        <nav
          className="isolate inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Previous</span>
            <svg
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          {getPageNumbers().map((number, index) =>
            number === "..." ? (
              <span
                key={index}
                className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
              >
                ...
              </span>
            ) : (
              <button
                key={index}
                onClick={() => handlePageChange(number)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                  page === number
                    ? "z-10 bg-[#1da1f2] text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  "
                    : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                }`}
              >
                {number}
              </button>
            )
          )}

          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages}
            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Next</span>
            <svg
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </nav>
      </div>
    </div>
  );
};

export default TeacherSubTable;
