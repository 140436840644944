import React, { useState, useEffect ,useCallback,useRef, memo} from 'react';
import { useStartAssignment } from '../../../utils/api/userApi';
import { toast } from 'react-toastify';

const AssignmentTimer = ({ selecAssignment, handleTimeUp , autoSaveDraft}) => {
  const [timeRemaining, setTimeRemaining] = useState(null);
  const { mutate: startAssignment, data: timerData } = useStartAssignment();
  const [isTimerActive, setIsTimerActive] = useState(false);
  const timerIntervalRef = useRef(null); // Use useRef to store the interval ID
console.log('timerIntervalRef',timerIntervalRef);

  // Handle Start Timer
  const handleStartTimer = useCallback(() => {
    const data = { studentAssignmentId: selecAssignment?.studentAssignmentId };
    
    startAssignment(data, {
      onSuccess: (response) => {
        if (response.success) {
          const totalSeconds = (response.minutesLeft * 60) + response.secondsLeft;
          setTimeRemaining(totalSeconds);
          setIsTimerActive(true);
          toast.success(response?.message);
          // fetchSavedContent();
        }
      },
      onError: (error) => {
        toast.error('Failed to start assignment');
      }
    });
  }, [selecAssignment?.studentAssignmentId, startAssignment, ]);


  useEffect(() => {
    if (timerData?.status === 'in-progress') {
      const totalSeconds = (timerData.minutesLeft * 60) + timerData.secondsLeft;
      setTimeRemaining(totalSeconds);
      setIsTimerActive(true);

      
    } else {
      // If not started, call handleStartTimer to start the timer
      handleStartTimer(); 
    }
  }, [ ]);

  // Timer countdown
  useEffect(() => {
    if (isTimerActive && timeRemaining > 0) {
      timerIntervalRef.current = setInterval(() => {
        setTimeRemaining((prev) => {
          if (prev <= 1) {
            clearInterval(timerIntervalRef.current);
            handleTimeUp();
            setIsTimerActive(false)
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timerIntervalRef.current);
  }, [isTimerActive, timeRemaining, handleTimeUp]);

  useEffect(() => {
    let autoSaveInterval

    if (isTimerActive && timerData?.status === 'in-progress') {
      autoSaveInterval = setInterval(() => {
        autoSaveDraft(); // Call the auto-save function
      }, 5000); // 3000 milliseconds = 3 seconds
    }

    return () => clearInterval(autoSaveInterval);
  }, [isTimerActive,autoSaveDraft]);

  // Format time to MM:SS
  const formatTime = (totalSeconds) => {
    if (totalSeconds === null) return '00:00';
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  // Warning notifications
  useEffect(() => {
    if (timeRemaining === 300) { // 5 minutes
      toast.warning('5 minutes remaining!');
    } else if (timeRemaining === 60) { // 1 minute
      toast.warning('1 minute remaining!');
    }
  }, [timeRemaining]);

  return (
    <div className="flex flex-col justify-end  ">
      {(!timerData?.isStarted && timerData?.status !== 'time-expired') && (
        <div className="text-center">
          <button
            onClick={handleStartTimer}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Start Assignment
          </button>
        </div>
      ) }
      {( timerData?.status === 'in-progress') && (
        <>
        <div className="flex gap-2 text-center items-center">
          <h3 className="text-lg font-bold ">Time Remaining :</h3>
          <div 
            className={`text-2xl font-bold border border-gray-600 shadow-lg rounded-lg p-1 ${
              timeRemaining <= 300 ? 'text-red-500' : 'text-blue-500'
            }`}
          >
            {formatTime(timeRemaining)}
          </div>
         
        </div>
         
          </>
      )}
    </div>
  );
}

export default memo(AssignmentTimer);