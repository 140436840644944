 import React from 'react'


 const EditEvaluation = ( {EditEvaluation,EditAiEvaluation,handleEvaluationChange}) => {

   return (
    <>
    <div className="flex gap-2 relative border border-gray-300 rounded-lg p-2 items-center justify-center">
    <input
      name="topic"
      value={EditEvaluation}
      onChange={handleEvaluationChange}
      className="block flex-1  outline-none rounded-lg  "
      placeholder="Enter text to change Evaluation"
      required
    />
  <button
  onClick={EditAiEvaluation}
  className={`  text-white py-2 font-bold text-[14px] px-2 rounded-lg  bg-[#4f24ff]`}
>
  Edit Evaluation
</button>
  </div>
</>
   )
}

 export default EditEvaluation
