import React from "react";
import DeleteModal from "../../../components/sharedUi/deleteModal";
import { toast } from "react-toastify";
import DeleteIcon from "../../../assets/delete.svg";
import ViewIcon from "../../../assets/view.svg";
import AssignmentIcon from '../../../assets/assignment.svg'
import Assignment from './studentAssignment';

const StudentAssgTable = ({
  studentAssignment,
  handleAssignmentOpen,
  handleAssignmentTextOpen,
  handleNavigate,
  totalPages,
  page,
  handlePageChange,
  getPageNumbers,
  
}) => {
  console.log("🚀 ~ StudentAssgTable ~ studentAssignment:", studentAssignment);

  const truncateText = (text, wordCount = 7) => {
    const words = text.split(" ");
    if (words.length > wordCount) {
      return words.slice(0, wordCount).join(" ") + "...";
    }
    return text;
  };
  const handleRowClick = async (Assignment) => {
    try {
      // First fetch the saved content
      // await refetchSavedContent(Assignment?.studentAssignmentId);
      // Then trigger the modal open
      handleAssignmentOpen(Assignment);
    } catch (error) {
      console.error("Error fetching saved content:", error);
      toast.error("Failed to fetch content");
    }
  };

  return (
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" class="px-6 py-3">
              Title
            </th>
            <th scope="col" class="px-6 py-3">
              Class Name
            </th>
            <th scope="col" class="px-6 py-3">
              Due Date
            </th>
            <th scope="col" class="px-6 py-3">
              Time Limit 
            </th>
            <th scope="col" class="px-6 py-3">
              Status
            </th>
            {/* <th scope="col" class="px-6 py-3">
                    Grading
                </th> */}
            <th scope="col" class="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {studentAssignment?.message !==
          "No assignments found for this student" ? (
            studentAssignment?.assignments?.map((assignment, index) => (
              <tr
                onClick={() => {handleRowClick(assignment)}}
                key={index}
                class="bg-white cursor-pointer border-b  hover:bg-gray-50 "
              >
                <td class="px-6 py-2">{assignment?.essayTitle}</td>
                <th class="px-6 py-2">{assignment?.classroomName}</th>
                <td class="px-6 py-2">
                  {new Date(assignment?.dueDate).toLocaleString()}
                </td>

                <td class="px-6 py-2">
                {assignment?.timeLimit ? (
                  assignment?.timeLimit + ' min'
                ):''
                }
                </td>

                <td class="px-6 py-2">{assignment?.status}</td>
                {/* <td class="px-6 py-2">
                    {assignment?.graded}
                </td> */}
                <td className="px-6 py-2 flex gap-2">
                  { assignment?.status !== "evaluated" ? (
                    ""
                  ) : (
                    <>
                    <div className="relative group">
                      <img
                        src={ViewIcon}
                        alt="View Evaluation"
                        onClick={(e) => handleNavigate(e, assignment)}
                        className="w-3 cursor-pointer"
                      />
                      <span className="absolute   transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        View Evaluation
                      </span>
                    </div>
                    </>
                  )}

                  { assignment?.status !== "evaluated" && assignment?.status !== "submitted" ? (
                    ""
                  ) : (
                    <>
                  <div className="relative group">
                  <img onClick={(e)=>handleAssignmentTextOpen(e,assignment)} src={AssignmentIcon} alt='assignment' className="w-3 cursor-pointer"/>
                  <span className="absolute    transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    Assignment
                  </span>
                </div>
                </>
                  )}

                  {/* <div className="relative group">
                  <img src={DeleteIcon} alt='Delete'  className="w-3 cursor-pointer"/>
                  <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    Delete
                  </span>
                </div> */}
                </td>
              </tr>
            ))
          ) : (
            <tr className="w-full h-full  flex justify-center items-center">
              <td class="px-6 py-4">
                <h1 className="text-[12px] font-[500]">No Assignment found </h1>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="flex w-full justify-end item-center p-2">
        <nav
          className="isolate inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Previous</span>
            <svg
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          {getPageNumbers().map((number, index) =>
            number === "..." ? (
              <span
                key={index}
                className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
              >
                ...
              </span>
            ) : (
              <button
                key={index}
                onClick={() => handlePageChange(number)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                  page === number
                    ? "z-10 bg-[#1da1f2] text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  "
                    : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                }`}
              >
                {number}
              </button>
            )
          )}

          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages}
            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Next</span>
            <svg
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </nav>
      </div>
    </div>
  );
};

export default StudentAssgTable;
