import React from 'react'
import { useNavigate } from 'react-router-dom'

const RegisterDialog = () => {
    const navigate=useNavigate()
  return (
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">   
      <div class="relative transform overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-full">
          <div class="flex justify-center">
           
             <h1 className='text-[28px] text-[#000000] font-[700] '>SignUp As</h1>
            
          </div>
        </div>
        <div class="flex justify-center p-5 w-full gap-10">
          <button onClick={()=>navigate('/register/student')} className="mt-3 w-full  rounded-md bg-white  px-5 py-3 text-sm font-semibold text-gray-900 shadow-sm border border-gray-600  sm:mt-0">Student</button>
          <button onClick={()=>navigate('/register/teacher')}  className="mt-3 w-full  rounded-md bg-white px-5 py-3 text-sm font-semibold text-gray-900 shadow-sm border border-gray-600  sm:mt-0 ">Teacher</button>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default RegisterDialog
