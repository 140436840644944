import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar/index";
import Navbar from "../components/Header/index";

const DashboardLayout=()=> {
  return (
    // <div className="flex  items-start  ">
    //   <Sidebar />
    //   <div className="flex flex-col  w-full h-full">
    //     <Navbar />
    //     <div className="">
    //       <Outlet />
    //     </div>
    //   </div>
    // </div>
    <div className="flex items-start h-screen">
  <Sidebar className="fixed top-0 left-0 h-full w-64" />
  <div className="flex flex-col w-full  h-full">
    <Navbar className="fixed top-0 left-0 right-0 z-10 w-full" />
    <div className="overflow-y-auto  h-full">
      <Outlet />
    </div>
  </div>
</div>
  );
}

export default DashboardLayout;

