import React from 'react'

const DeleteModal = ({text,isOpen,handleDelete,closeModal}) => {
    if (!isOpen) return null;
  return (
    <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
  
    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">   
        <div className="relative transform overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div className="bg-white p-4 w-full">
            <div className='border-b border-gray-300 flex justify-start pb-1'>
               <h1 className='text-[20px] text-[#000000] font-[600]  '>Delete</h1>

            </div>
            <div className="flex  items-center">
             
               <h1 className='text-[18px] text-gray-400 font-[500] pt-7'>{text}?</h1>
              
            </div>
          </div>
          <div className="flex justify-end p-5 w-full gap-2">
            <button onClick={closeModal} className="mt-3  rounded-md   bg-white  px-5 py-2 text-sm font-semibold text-gray-900 shadow-sm border border-gray-600  sm:mt-0">Cancel</button>
            <button onClick={handleDelete}  className="mt-3    rounded-md bg-blue-900 px-5 py-2 text-sm font-semibold text-white shadow-sm border border-gray-600  sm:mt-0 ">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
    
  )
}

export default DeleteModal
