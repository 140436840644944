import React from 'react'
import { useNavigate } from 'react-router-dom'
const Tools = () => {
const navigate=useNavigate()
  const handlenavigate=()=>{
    navigate('/dashboard/tools/essay-title')
  }
  return (
    <div className=' flex p-10 gap-10'>
     <div onClick={handlenavigate} className='w-80 cursor-pointer bg-[#FFFFFF] border border-[#EDF9FF] p-4 shadow-lg rounded-lg  flex justify-center items-center'>
        <h1 className='text-[20px] font-bold'>Essay Title Generator</h1>
     </div>
     <div className='w-80 bg-[#FFFFFF] border cursor-pointer border-[#EDF9FF] p-4 shadow-lg rounded-lg ew-[100px] flex justify-center items-center'>
        <h1 className='text-[20px] font-bold'>Comprehension</h1>
     </div>
    </div>
  )
}

export default Tools
