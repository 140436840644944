// src/context/NotificationContext.js
import React, { createContext, useState, useEffect } from 'react';
import pusher from '../sharedUi/pusher';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';



export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {

    const navigate=useNavigate()
    const data=JSON.parse(localStorage.getItem('UserData'))
    const [notifications, setNotifications] = useState([]);
  const handleOpenNotification=()=>{
        navigate('dashboard/notification')
        setNotifications([])
  }
console.log('notification',notifications)

useEffect(() => {
  // Exit if user data is not yet loaded
  if (!data?.role || !data?.userId) return;

 // Determine channel and event based on user role
 let channelName = '';
 let eventName = '';
//  let shouldSubscribe = false; // Add this flag


 if (data.role === 'student' ) {
   channelName = `student-${data?.userId}`;
   eventName = 'new-assignment';
  //  shouldSubscribe = true;

 } else if (data.role === 'teacher' ) {
   channelName = `teacher-${data?.userId}`;
 console.log(`channelName: ${channelName}`);

   eventName = 'new-submission';
  //  shouldSubscribe = true;

 }

//  if (!shouldSubscribe) return;

 // Subscribe to the determined channel
 const channel = pusher.subscribe(channelName);
 console.log(`Subscribed to channel: ${channelName}`);

 // Bind to the determined event
 channel.bind(eventName, (notification) => {
    if(notification){
      console.log('data23232',notification)
      setNotifications((prev) => [
        ...prev,
        notification
      ]);
      // refetchNotification()

    }
 
           // Update notifications state
           
 
           // Display toast notification based on type
          //  if (type === 'new_essay_assigned') {
          //    toast.info(`${title}: ${message}`);
          //  } else if (type === 'essay_submitted') {
          //    toast.success(`${title}: ${message}`);
          //  } else {
          //    toast.info(`${title}: ${message}`);
          //  }
        
      
 });

 // Handle subscription errors
 channel.bind('pusher:subscription_error', (status) => {
   console.error(`Subscription error on channel ${channelName}:`, status);
 });

 // Cleanup on unmount or when user changes
 return () => {
   pusher.unsubscribe(channelName);
   console.log(`Unsubscribed from channel: ${channelName}`);
 };
}, [data?.role, data?.userId]);

  return (
    <NotificationContext.Provider value={{ notifications,handleOpenNotification,setNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};