import React, { useState, useEffect } from "react";
import SearchFilter from "../../../components/searchFilter";
import TitleList from "../../../components/titleItem";
import AiButton from "../../../components/AiButton";
import { Topics } from "../../../utils/constants";
import axios from "axios";
import {
  useGetClasses,
  useGenerateAiTitle,
  useSaveEssayTopics,
} from "../../../utils/api/userApi";
import { toast } from "react-toastify";
import { useClassContext } from "../../../components/context/classContext";
import Loader from "../../../components/sharedUi/loader";
import { useNavigate } from "react-router-dom";
import BackIocn from "../../../assets/arrowBackIcon.svg";

import FormValidationError from "../../../components/sharedUi/FormValidatorError";
import RubricTable from "./rubricTable";
const EssayGenerator = () => {
  const logData = JSON.parse(localStorage.getItem("UserData"));
  const navigate = useNavigate();
  const { data: classes } = useGetClasses(logData?.userId);
  console.log("🚀 ~ EssayGenerator ~ classes:", classes);
  const { mutate: generateAiTitle, isPending } = useGenerateAiTitle();
  const { mutate: saveEssayTopics, isPending: saveEssay } =
    useSaveEssayTopics();
  const { selectedClass } = useClassContext();
  console.log("🚀 ~ EssayGenerator ~ selectedClass:", selectedClass);
  const [essayTitles, setEssayTitles] = useState([]);
  const [selectClass, setSelectClass] = useState(selectedClass?.id);
  const [dueDate, setDueDate] = useState();
  const [dueDateError, setDueDateError] = useState("");
  const [timeLimit, setTimeLimit] = useState("");
  const [topicError, setTopicError] = useState("");
  const [filterEssayRubric, setFilterEssayRubric] = useState();
  const [currentView, setCurrentView] = useState('initial'); // 'initial', 'titleList', 'assignmentSetup'
console.log("currentView",currentView)

  const [aigenEssayRubric, setAiGenEssayRubric] = useState();
  console.log("klsdjkhasldh3289", aigenEssayRubric);
  const [selectedTitle, setSelectedTitle] = useState(null);

  const [modifyPrompt, setModifyPrompt] = useState();

  const handleModifyPrompt = (e) => {
    setModifyPrompt(e.target.value);
  };

  const [filters, setFilters] = useState({
    topic: "",
    Grade: selectedClass?.classroom_grade,
  });
  const handleTimeLimit = (e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9]+$/.test(value)) {
      setTimeLimit(value);
    }
  };

  const GenerateAiTitle = async () => {
    if (filters?.topic === "") {
      return setTopicError("Field Required");
    }
    const data = { query: filters?.topic, grade: filters?.Grade };

    generateAiTitle(data, {
      onSuccess: (response) => {
        console.log("response12121", response);
        setEssayTitles(response?.topics);
        setCurrentView('titleList'); // Set view to title list after generation

        toast.success(response?.message);
      },
      onError: (error) => {
        toast.error("Failed to approve evaluation: " + error.message);
      },
    });
  };

  const handleSelectedTitle = (title) => {
    setSelectedTitle(title);
    setCurrentView('assignmentSetup');

    
  };

  const SaveGeneratedEssay = async () => {
    const data = {
      selectedTopic: selectedTitle?.title,
      grade: filters?.Grade,
      modificationPrompt: modifyPrompt || "",
      rubricId: aigenEssayRubric?.rubricId || null,
    };
    saveEssayTopics(data, {
      onSuccess: (response) => {
        console.log("saveResponse", response);
        setAiGenEssayRubric(response);
        toast.success(response?.message);
        setModifyPrompt("");
      },
      onError: (error) => {
        toast.error("Failed to approve evaluation: " + error.message);
      },
    });
  };

  

  const AsssignTopic = async () => {
    if (!dueDate) {
      return setDueDateError(" Due Date is Required");
    }
    const data = {
      essay_id: selectedTitle?.id || aigenEssayRubric?.essayId,
      classroom_id: selectClass,
      due_date: dueDate,
      timeLimit: timeLimit,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_LOCAL_URL}/classroom/assign-essay`,
      data
    );
    if (response.status === 201) {
      toast.success(response?.data?.message);
      navigate("/dashboard/assignments");
    } else {
      toast?.error(response?.data?.message);
    }
  };

  const handleselectClass = (e) => {
    const value = e.target.value;
    if (value === "") {
      return toast.error("Please Select Valid Class");
    } else {
      setSelectClass(e.target.value);
    }
  };
  const handleDuedate = (e) => {
    const selectedDate = e.target.value;
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

    if (selectedDate < today) {
      setDueDateError("Due date cannot be in the past.");
      setDueDate(""); // Optionally clear the due date
    } else {
      setDueDate(selectedDate);
      setDueDateError(""); // Clear error if the date is valid
    }
  };

  useEffect(() => {
     if (selectedTitle) {
      SaveGeneratedEssay();
    }
  }, [selectedTitle]);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
    setTopicError("");
  };

  const handleBack = () => {
    switch (currentView) {
      case 'titleList':
        setCurrentView('initial');
        setEssayTitles([]);
        break;
      case 'assignmentSetup':
        setCurrentView('titleList');
        setSelectedTitle(null);
        break;
      default:
        navigate(-1); // Only use router navigation when at initial view
    }
  };

  return (
    <>
      {(isPending || saveEssay) && <Loader />}
    
      <div className="bg-gray-100 min-h-screen px-6 flex flex-col  item-center">
      
        <p
          className="flex gap-2 items-center py-2  cursor-pointer"
          onClick={handleBack}
        >
          <img src={BackIocn} alt="backicon" />
          Back
        </p>
    
        <div className="bg-[#FFFFFF] p-4 w-full h-auto mb-0  rounded-lg  ">
          {currentView === 'assignmentSetup'  ? (
            <div className="flex   flex-col text-left">
              <div className="flex justify-center items-center w-full gap-4">
                <label className="flex-1">
                  <span className="text-gray-700">Select Class:</span>
                  <select
                    value={selectClass}
                    onChange={handleselectClass}
                    className="block w-full mt-1 p-2 border border-gray-300 rounded-md"
                  >
                    <option value="">Select Class</option>
                    {classes?.classrooms?.map((classroom) => (
                      <option value={classroom?.id}>{classroom?.name}</option>
                    ))}
                  </select>
                </label>
                <label className="flex-1">
                  <span className="text-gray-700">Due Date</span>
                  <input
                    aria-label="Date and time"
                    type="datetime-local"
                    value={dueDate}
                    onChange={handleDuedate}
                    className="block w-full mt-1 p-2 border border-gray-300 rounded-md"
                    placeholder="Set Due Date"
                  />
                  {dueDateError && (
                    <FormValidationError errors={dueDateError} />
                  )}
                </label>
                <label className="flex-1">
                  <span className="text-gray-700">Time Limit(min)</span>
                  <input
                    type="text"
                    value={timeLimit}
                    onChange={handleTimeLimit}
                    className="block w-full mt-1 p-2 border border-gray-300 rounded-md"
                    placeholder="Enter Time Limit (10min)"
                  />
                </label>
                
                <button
                  onClick={AsssignTopic}
                  className={`h-11 mt-7 bg-blue-700 w-[180px] text-white py-2 px-2 rounded-lg`}
                >
                  Assign Topic
                </button>
                {/* <AiButton generateTitle={AsssignTopic} title={"Assign Topic"} /> */}
              </div>
              <div className="w-full mt-2">
                <RubricTable aigenEssayRubric={aigenEssayRubric} />
              </div>
              <div>
                <div className="flex gap-2 w-full pb-2">
                  <div className="flex relative w-full border border-gray-300 rounded-lg p-2 items-center justify-center">
                    <input
                      name="topic"
                      value={modifyPrompt}
                      onChange={handleModifyPrompt}
                      className="block flex-1 w-full  outline-none rounded-lg  "
                      placeholder="Tell me how to improve this rubric! 'Make it tougher or simplify levels description,add more criteria'"
                      required
                    />
                  </div>
                  <button
                    onClick={SaveGeneratedEssay}
                    className={` text-white  font-semibold text-[18px] px-6 rounded-lg w-[200px] bg-blue-700`}
                  >
                    Modify Rubric
                  </button>
                  <div />
                </div>
              </div>
            </div>
          ) : currentView === 'titleList' ? (
            
            <div>
              {essayTitles?.length !== 0 && (
                <>
                  <div className=" w-full  pb-4">
                    <p className="text-[16px] font-semibold text-[#000">
                      Ai Generated Topics
                    </p>
                  </div>
                  <TitleList
                    title={essayTitles}
                    handleSelectedTitle={handleSelectedTitle}
                  />
                  <div className="flex relative border border-gray-300 rounded-lg p-2 items-center justify-center">
                    <input
                      name="topic"
                      value={filters.topic}
                      onChange={handleFilterChange}
                      className="block flex-1  outline-none rounded-lg h-10 "
                      placeholder=""
                      required
                    />
                    {filters?.Grade && (
                      <div>
                        <span className="text-[#000] flex-1 font-semibold text-[16px]">
                          {" "}
                          For Grade {filters?.Grade}
                        </span>
                      </div>
                    )}
                  </div>
                  <button
                    onClick={GenerateAiTitle}
                    className={`mt-14  text-white py-3 font-bold text-[18px] px-6 rounded-lg  ${
                      essayTitles?.length !== 0 ? "w-full" : "w-[750px]"
                    } bg-[#4f24ff]`}
                  >
                    Generate More Topics
                  </button>
                </>
              )}
              </div>
               ): (
                <>
                  <div className="flex flex-col justify-center items-center w-full  pb-10">
                    <h1 className="text-[28px] font-[700]  text-[#000] ">
                      Generate Essay Topics{" "}
                    </h1>
                    <p className="text-[16px] font-semibold text-gray-500">
                      Generate high-quality essay topics using AI, search past papers
                    or assign your own topic
                    </p>
                    <SearchFilter
                      filters={filters}
                      topicError={topicError}
                      handleFilterChange={handleFilterChange}
                    />

                    <div className="flex justify-between ">
                      <button
                        onClick={GenerateAiTitle}
                        className={`mt-14  text-white py-3 font-bold text-[18px] px-6 rounded-lg w-[750px] bg-[#4f24ff]`}
                      >
                        Generate Essay Topics
                      </button>
                      
                    </div>
                  </div>
                </>
              )}
            </div>
      </div>
    </>
  );
};

export default EssayGenerator;
