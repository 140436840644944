import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetEvaluation,
  useGetStudentEvaluation,
} from "../../../utils/api/userApi";
import { useApproveEvaluation } from "../../../utils/api/userApi";
import { toast } from "react-toastify";
import BackIocn from "../../../assets/arrowBackIcon.svg";
import DOMPurify from 'dompurify';
const StudentAssignmentEvaluation = () => {
  const { state } = useLocation();
  console.log("🚀 ~ AssignmentEvaluation ~ state:", state);
  const navigate = useNavigate();
  const StudentId = JSON.parse(localStorage.getItem("UserData"));

  const { data: studentEvaluation } = useGetStudentEvaluation(
    state?.assignment?.studentAssignmentId
  );
  console.log(
    "🚀 ~ StudentAssignmentEvaluation ~ studentEvaluation:",studentEvaluation
    
  );

  const handleNavigate = () => {
    navigate(-1);
  };

  return (
    <div className="p-5">
      <div>
        <p
          className="flex gap-2 items-center py-2 cursor-pointer"
          onClick={handleNavigate}
        >
          <img src={BackIocn} alt="backicon" />
          Back
        </p>
      </div>
      <div className="flex flex-col gap-4 border border-gray-300 rounded-lg w-full shadow-lg p-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <h1 className="text-xl font-bold ">Essay Title :</h1>
            <p className="bg-gray-50 font-[500] text-[16px] p-2 rounded-lg">
              {studentEvaluation?.evaluation?.essay_title}
            </p>
          </div>
        </div>
        <div className="p-6 bg-gray-50 rounded-lg">
          <h2 className="text-xl font-semibold mb-4">
            Evaluation of the Student Essay
          </h2>
          <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(studentEvaluation?.evaluation?.html_evaluation) }}
            ></div>
      
            {/* <>
             {studentEvaluation?.evaluation?.teacher_evaluation?.scores?.length !== 0?(
              <>
              <h3 className="text-lg font-semibold mb-2">Scores:</h3>
              <ul className="list-disc pl-5 mb-4">
               
                {studentEvaluation?.evaluation?.teacher_evaluation?.scores?.map(
                  (score, index) => (
                    <li key={index} className="text-gray-700">
                      <span className="font-bold">{score.label}:</span>{" "}
                      <span className="">{score.value}</span>
                    </li>
                  )
                )}
              
              </ul>
              </>
):''}
              {studentEvaluation?.evaluation?.teacher_evaluation?.briefSummary ? (
                <>
                  <h3 className="text-lg font-semibold py-2">Brief Summary:</h3>
                  <p className="text-gray-600">
                    {studentEvaluation?.evaluation?.teacher_evaluation?.briefSummary}
                  </p>
                </>
              ) : (
                ""
              )}
              {!studentEvaluation?.evaluation?.teacher_evaluation?.detailedFeedback ||
              studentEvaluation?.evaluation?.teacher_evaluation?.detailedFeedback === 'N/A'? (
            ''
              ) : (
                <>
                <h3 className="text-lg font-semibold mb-2">
                  Detailed Feedback:
                </h3>
                <p className="text-gray-600">
                  {
                    studentEvaluation?.evaluation?.teacher_evaluation
                      ?.detailedFeedback
                  }
                </p>
              </>
              )}
              {studentEvaluation?.evaluation?.teacher_evaluation?.finalScore ? (
                <>
                  <h3 className="text-lg font-semibold mb-2">Final Score:</h3>
                  <p className="text-gray-600">
                    {studentEvaluation?.evaluation?.teacher_evaluation?.finalScore}
                  </p>
                </>
              ) : (
                ""
              )}
            </> */}
         
        </div>
      </div>
    </div>
  );
};

export default StudentAssignmentEvaluation;
