import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
const ClassContext = createContext();

export const useClassContext = () => useContext(ClassContext);

export const ClassProvider = ({ children }) => {

 const [selectedClass,setSelectedClass]=useState()
 const navigate = useNavigate();
const handleSelectedClass=(classItem)=>{
  
  setSelectedClass(classItem)
  navigate('/dashboard/tools');
}


  

  const value = {
    handleSelectedClass,
    selectedClass
  };

  return <ClassContext.Provider value={value}>{children}</ClassContext.Provider>;
};
