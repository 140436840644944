import React from 'react'
import DOMPurify from 'dompurify'
import CloseIcon from "../../../assets/closeIcon.svg";

const StudentAssignmentText = ({handleAssignmentTextClose,assignmentTextOpen,AssigText}) => {
    console.log(AssigText,"AssigText")
  return (
    
    <>
      {assignmentTextOpen && (
        <div
          class="relative z-50"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          <div class="fixed inset-0 z-10 w-screen px-20 ">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div class="pb-4 relative transform overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all ">
                <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-full">
                  <div class="flex justify-between items-center pb-4">
                    <h1 className="text-[18px] text-[#000000] font-[700] ">
                      Submitted Assignment
                    </h1>
                   <img   src={CloseIcon} alt="close" className="w-5 cursor-pointer" onClick={handleAssignmentTextClose}/>
                  </div>

                  <div class="border border-gray-200 rounded-lg p-2 ">
                  <div className='text-left' dangerouslySetInnerHTML={{ __html: AssigText?.submissionText }}></div>
                  </div>
                 
                </div>
              
              </div>
            </div>
          </div>
        </div>
      )}
</>
  )
}

export default StudentAssignmentText
