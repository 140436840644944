
import React, { useState,useEffect } from "react";
import { toast } from 'react-toastify'
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useGetAllStudent ,useUpdateClass} from "../../utils/api/userApi";

const EditClass = ({editClassOpen,handleEditClassClose,editSelectClassOpen}) => {

  const logData=JSON.parse(localStorage.getItem('UserData'))
  const { data: student }=useGetAllStudent()
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [grade, setGrade] = useState("");
  const [className, setClassName] = useState('');
  const {mutate:updateClass}=useUpdateClass()
  const registeredStudents = student && student?.students?.map((student) => ({
    label: student?.name, // This will be shown in the dropdown
    value: student?.id, // The value to be used in the form
  }))|| [];
  
  const handleGradeChange=(e)=>{
    setGrade(e.target.value)
  }
  const handleInputChange = (event) => {
    const value= event.target.value;
    setClassName(value)
  };

  const UpdateClassroom= async ()=>{
    const selectedEmails = selectedStudents?.map((student) => student?.value ? student?.value :student);
  
    const data={
      name:className,
      student_ids:selectedEmails,
      grade:grade
    }
    const id=editSelectClassOpen?.id
    updateClass({id,data}, {
      onSuccess: (response) => {
          toast.success(response?.data?.message );
          handleEditClassClose()
      },
      onError: (error) => {
          toast.error( error.response?.data?.message);
      }
  })
    
  }
  useEffect(()=>{
    setClassName(editSelectClassOpen?.name || '')
    if (editSelectClassOpen?.student_ids) {
      const preSelectedStudents = registeredStudents?.filter(student => 
        editSelectClassOpen?.student_ids?.includes(student?.value)
      );
        console.log("🚀 ~ useEffect ~ preSelectedStudents:", preSelectedStudents)
      setSelectedStudents(preSelectedStudents);
  }
    setGrade(editSelectClassOpen?.classroom_grade || null)
  },[editSelectClassOpen])

 
  return (
    editClassOpen && (

   
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
  
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">   
        <div class="relative transform overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div className="max-w-xl mx-auto p-4">
      {/* Form */}
      <h2 className="flex justify-start border-b border-gray-200 text-2xl font-bold mb-4  text-center">Update  Class</h2>
      <div onSubmit={UpdateClassroom} className="">
        {/* Class Name Input */}
        <div className="flex flex-col gap-2">
          <div className="w-full">
            <label
              className=" text-lg flex justify-start font-semibold mb-2"
              htmlFor="className"
            >
              Class Name
            </label>
            <input
              type="text"
              id="className"
              name="className"
              value={className}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500"
              placeholder="Enter class name"
              required
            />
          </div>
          <label className="flex justify-start text-lg font-semibold mb-2">
          Select Students
        </label>
        <MultiSelect
          options={registeredStudents}
          value={selectedStudents}
          onChange={setSelectedStudents}
          labelledBy="Select"
          className="z-[100]"

          
        />
          <label className="flex justify-start text-lg font-semibold mb-2">
          Select Grade
        </label>
        <select 
        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500"
        onChange={handleGradeChange}
        value={grade}
        >
          <option value={5}>Grade 5</option>
          <option value={6}>Grade 6</option>
          <option value={7}>Grade 7</option>
          <option value={8}>Grade 8</option>
          <option value={9}>Grade 9</option>
          <option value={10}>Grade 10</option>
          <option value={11}>Grade 11</option>
        </select>
        </div>
        <div className="h-[30vh]"></div>
        <div className="text-center flex gap-2 justify-end w-full">
        <button
            onClick={handleEditClassClose}
            className="px-6 py-1 border border-gray-500 text-[#000] rounded-lg  font-semibold"
          >
            Cancel
          </button>
          <button
            onClick={UpdateClassroom}
            className="px-6 py-1 bg-blue-500 text-white rounded-lg hover:bg-blue-600 font-semibold"
          >
            Update Class
          </button>
        </div>
      </div>
    </div>
        </div>
      </div>
    </div>
  </div>
)
  )
}

export default EditClass
