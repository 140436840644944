import React,{useState} from 'react'

import Lock from "../../assets/lock.png";
// import Email from "../../assets/Email.png";
import { useResetPassword } from '../../utils/api/authApis';
import FormValidationError from '../../components/sharedUi/FormValidatorError'
import {useParams, useNavigate } from 'react-router-dom';
import Loader from '../../components/sharedUi/loader'
import { toast } from 'react-toastify';
const ResetPassword = () => {

const {mutate:resetPassword,isPending }=useResetPassword()
const { token } = useParams();

  console.log('Token from URL:', token);
    const [password,setPassword] = useState('')
    const [error,setError] = useState('')
    const navigate=useNavigate()
    const handlePasswordChange=(e)=>{
        setPassword(e.target.value)
        setError('')
    }
    const handleSubmit=()=>{
        if(!password){
          return setError('Password is required')
        }
        const data={
            token:token,
            newPassword:password
        }
        resetPassword(data,{
            onSuccess:(response)=>{
                console.log('response244324',response)
                toast.success(response?.message)
                navigate('/')
                
            },
            onError :(error)=>{
                toast.error(error?.message)
            }
        })
    }
  return (
    <>
    {isPending && <Loader/>}
    <div className="flex justify-center items-center h-screen">
    <div className="flex flex-grow flex-col justify-center items-center gap-10  px-[6rem] h-[100vh]">
      {/* <img src={Logo} alt="Crypto Wallet" className="mb-4" /> */}
      <h1
        variant="h4"
        style={{ fontFamily: "Poppins" }}
        className="mb-6 font-bold text-[40px]  font-Poppins"
      >
        Reset Password
      </h1>
      <div className='w-[460px] bg-white '>
        <div className="mb-12 ">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Password
            </label>
            <div
              className="relative "
              style={{ outline: "none", border: "none" }}
            >
              <img
                src={Lock}
                alt="Lock"
                className="absolute z-10 left-2 top-1/2 transform -translate-y-1/2 "
              />
              <div className="absolute  left-10 top-1/2 transform -translate-y-1/2 h-12  border-r-4 border-[#FFFFFF] "></div>
              <input
                type="password"
                id="password"
                size="lg"
                placeholder="Enter New Password"
                value={password}
                onChange={handlePasswordChange}
                
                style={{ backgroundColor: "#FAFAFA", outline: "none" }}
                className="w-full pl-12 py-2.5 rounded-lg  text-gray-900 border-none focus:border-none"
              />
            </div>
            {error && (
              <FormValidationError errors={error} />
            )}
          </div>
          <button
            onClick={handleSubmit}
            size="lg"
            className="w-full mb-4 py-2 bg-[#000000] normal-case text-[#FFFFFF] text-[18px] font-[500] rounded-lg"
          >
            Reset Password
          </button>
          </div>
      
    </div>
    </div>
    </>
  )
}

export default ResetPassword
