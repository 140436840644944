import Login from "../pages/auth/login.js";
import SignUp from "../pages/auth/signup.js";
import TeacherDashboard from "../pages/dashboard/teacherDashboard.js";
import {Navigate ,useRoutes} from "react-router-dom";
import DashboardLayout from "../layout/dashboardLayout.js";
import EssayGenerator from '../pages/tools/essayGenerator/essay-generator.js'
import CreateClass from "../pages/createClass/index.js";
import ClassData from '../pages/createClass/ClassData.js'
import RegisterDialog from "../pages/auth/registerDialog.js"
import RegisterStudent from '../pages/auth/registerStudent.js'
import StudentDashboard from '../pages/dashboard/studentDashboard.js'
import StudentAssignment from "../pages/Assignment/studentAssignment/studentAssignment.js"
import TeacherAssignment from '../pages/Assignment/teacherAsignment/teacherAssignment.js'
import TAssigDetail from '../pages/Assignment/teacherAsignment/TAssignmentDetails.js'
import AssignmentEvaluation from "../pages/Assignment/teacherAsignment/assignmentEvaluation.js";
import Tools from "../pages/tools/index.js";
import StudentAssignmentEvaluation from "../pages/Assignment/studentAssignment/studentEvaluation.js";
import StudentSubmissionTable from "../pages/Assignment/teacherAsignment/getStudentSubmissions.js";
import ForgotPassword from "../pages/auth/forgotPassword.js";
import ResetPassword from "../pages/auth/resetPassword.js";
import Notification from "../pages/Notificarion/notification.js";
import AssignmentEditing from "../pages/Assignment/studentAssignment/assignmentEditing.js";


function AppRoutes() {
  const UserRole =JSON.parse(localStorage.getItem('UserData'))
  console.log("🚀 ~ Sidebar ~ Role:", UserRole)
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          element:UserRole?.role === 'teacher'? <Navigate to="/dashboard/teacher" />:<Navigate to="/dashboard/student" />,
          index: true
        },
        {
          path:UserRole?.role === 'teacher'? 'teacher':'student',
          element: UserRole?.role === 'teacher' 
            ? <TeacherDashboard /> 
            : UserRole?.role === 'student' 
              ? <StudentDashboard /> 
              : <Navigate to="/" />, // Redirect if role is unknown
        },
        {
          path: 'tools',
          element: UserRole?.role === 'teacher' ? <Tools /> : <Navigate to="/dashboard" />
          
        },
        {
          path: '/dashboard/tools/essay-title',
          element: UserRole?.role === 'teacher' ? <EssayGenerator /> : <Navigate to="/dashboard" />
          
        },
      
        {
          path:'create-class',
          element:UserRole?.role === 'teacher' ? <ClassData /> : <Navigate to="/dashboard" />
        },
        {
          path:'student-submissions',
          element:UserRole?.role === 'teacher' ? <StudentSubmissionTable /> : <Navigate to="/dashboard" />
        },
        {
          path:'assignment',
          element:UserRole?.role === 'student' ? <StudentAssignment /> : <Navigate to="/dashboard" />
        },
        {
          path:'assignment/editing',
          element:UserRole?.role === 'student' ? <AssignmentEditing /> : <Navigate to="/dashboard" />
        },
        {
          path:'assignment/evaluation',
          element:UserRole?.role === 'student' ? <StudentAssignmentEvaluation /> : <Navigate to="/dashboard" />
        },
        {
          path:'assignments',
          element:UserRole?.role === 'teacher' ? <TeacherAssignment /> : <Navigate to="/dashboard" />
        },
        {
          path:'assignments/detail',
          element:UserRole?.role === 'teacher' ? <TAssigDetail /> : <Navigate to="/dashboard" />
        },
        {
          path:'assignments/evaluaton',
          element:UserRole?.role === 'teacher' ? <AssignmentEvaluation /> : <Navigate to="/dashboard" />
        },
        {
          path:'notification',
          element:UserRole?.role === 'teacher' || UserRole?.role === 'student' ? <Notification /> : <Navigate to="/dashboard" />
        }
      ]
    },
    {
      path: '/',
      element: <Login />
    },
    {
      path: '/register',
      element: <RegisterDialog/>
    },
    {
      path: 'register/student',
      element: <RegisterStudent/>
    },
    {
      path: 'register/teacher',
      element: <SignUp/>
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword/>
    },
    {
      path: '/reset-password/:token',
      element: <ResetPassword/>
    },

  ])
  return routes;
}

export default AppRoutes;
