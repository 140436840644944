import React, { useState,useContext } from "react";

import Loader from "../../components/sharedUi/loader";
import { useGetStudentStats } from "../../utils/api/userApi";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid} from 'recharts';
import { BookOpen, Clock,BookCheck, FileCheck} from 'lucide-react';


const COLORS = ['#10B981', '#F59E0B', '#3B82F6', '#EC4899', '#8B5CF6'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value, name, index }) => {
  const radius = outerRadius * 1.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
  // Calculate the position for the line's end point
  const lineX = cx + (outerRadius + 10) * Math.cos(-midAngle * RADIAN);
  const lineY = cy + (outerRadius + 10) * Math.sin(-midAngle * RADIAN);
  
  // Determine text anchor based on which side of the pie the label is on
  const textAnchor = x > cx ? 'start' : 'end';
  
  return (
    <g>
      {/* Line from pie to label */}
      <path
        d={`M ${cx + outerRadius * Math.cos(-midAngle * RADIAN)},${
          cy + outerRadius * Math.sin(-midAngle * RADIAN)
        }L ${lineX},${lineY}L ${x},${y}`}
        stroke={COLORS[index % COLORS.length]}
        fill="none"
      />
      {/* Circle at the bend point */}
      <circle cx={lineX} cy={lineY} r="2" fill={COLORS[index % COLORS.length]} />
      
      {/* Label text */}
      <text
        x={x}
        y={y}
        textAnchor={textAnchor}
        fill={COLORS[index % COLORS.length]}
        dominantBaseline="central"
        className="text-sm font-medium"
      >
        {`${value} (${(percent * 100).toFixed(1)}%)`}
      </text>
    </g>
  );
};

const StudentDashboard = () => {
  const user=JSON.parse(localStorage.getItem("UserData"));
  const {data:studentStats ,isPending}=useGetStudentStats(user?.userId)
  console.log("🚀 ~ StudentDashboard ~ data:", studentStats)
  
  const chartData = [
    { name: 'Submitted', value: parseInt(studentStats?.submittedAssignments) ,icon:BookCheck},
    { name: 'Pending', value: parseInt(studentStats?.pendingAssignments), icon:BookOpen},
    { name: 'Evaluated', value: parseInt(studentStats?.evaluatedAssignments), icon:FileCheck},
    { name: 'In Progress', value: parseInt(studentStats?.inProgressAssignments) ,icon:Clock},
  ];
  const total = chartData?.reduce((sum, item) => sum + item.value, 0);



  return (
    <>
    {isPending && <Loader/>}
   <div className="flex flex-col p-5 gap-4">
    
   <div className="bg-white p-6 rounded-xl shadow-lg border border-gray-200">
      <h2 className="text-xl font-bold mb-4">Student Assignment Stats</h2>
      
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        {chartData?.map((item, index) => (
          <div className='flex items-center justify-between p-4 rounded-lg'style={{ backgroundColor: `${COLORS[index % COLORS.length]}15` }}>
          <div 
            key={item.name} 
            className="p-3 rounded-lg"
           
          >
            <p className="text-sm text-gray-600">{item.name}</p>
            <p className="text-xl font-bold" style={{ color: COLORS[index % COLORS.length] }}>
              {item.value}
            </p>
            <p className="text-xs text-gray-500">
              {((item.value / total) * 100).toFixed(1)}%
            </p>

            
          </div>
          <item.icon 
                className="w-10 h-10"
                style={{ color: COLORS[index % COLORS.length] }}
              />
          </div>
        ))}
      </div>

      <div className="h-[350px]"> {/* Increased height to accommodate labels */}
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
              labelLine={false}
              label={renderCustomizedLabel}
            >
              {chartData?.map((_, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
   </div>
   
  
     
    </>
  );
};

export default StudentDashboard;
