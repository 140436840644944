import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetEvaluation } from "../../../utils/api/userApi";
import { useApproveEvaluation } from "../../../utils/api/userApi";
import { toast } from "react-toastify";
import EditEvaluations from "./editEvaluation";

import DOMPurify from 'dompurify';
import Loader from "../../../components/sharedUi/loader";
import BackIocn from "../../../assets/arrowBackIcon.svg";


const AssignmentEvaluation = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [evaluations,setEvaluations]=useState()
  const TeacherId = JSON.parse(localStorage.getItem("UserData"));
  console.log("🚀 ~ AssignmentEvaluation ~ state:", state);
  const [EditEvaluation,setEditEvaluation]=useState()
  const { mutate: evaluation ,isPending } = useGetEvaluation();
  const { mutate: approveEvaluation, onSuccess } = useApproveEvaluation();

 


  const handleApproveEvaluation = () => {
    let data = {
      submissionId: evaluations?.evaluation?.submission_id,
      evaluationId: evaluations?.evaluation?.id,
      teacherID: TeacherId?.userId,
      teacherComments:evaluations?.evaluation?.html_evaluation
    };
    approveEvaluation(data, {
      onSuccess: (response) => {
        console.log("🚀 ~ handleApproveEvaluation ~ response:", response);
        // Show success toast
        toast.success(response?.message || "Evaluation approved successfully!");
        // Navigate back to the previous route
       
        navigate(-1); // Go back to the previous route
      },
      onError: (error) => {
        // Show error toast if needed
        toast.error("Failed to approve evaluation: " + error.message);
      },
    });
  };
  
  const handleEditAiEvaluation = () => {
    if (!EditEvaluation.trim()) {
      toast.error("Please enter modification prompt");
      return;
    }
    fetchEvaluation(EditEvaluation);
  };
  const handleEvaluationChange=(e)=>{
    setEditEvaluation(e.target.value)
  }

  useEffect(() => {
    if (state?.evaluation_id) {
      fetchEvaluation();
    }
  }, [state?.evaluation_id]);

  const fetchEvaluation = (EditEvaluation = '') => {
    const data = {
      id: state?.evaluation_id,
      modificationPrompt: EditEvaluation,
      
    };

    evaluation(data, {
      onSuccess: (response) => {
        console.log("response:", response);
        setEvaluations(response)
        toast.success(response?.message || "Evaluation successful!");
      },
      onError: (error) => {
        toast.error("Evaluation failed: " + error.message);
      },
    });
  };
  const handleNavigate = () => {
    navigate(-1);
  };
 
  return (
    <>
    {isPending && <Loader/>}
      <div className="p-5">
      <p
          className="flex gap-2 items-center py-2 cursor-pointer"
          onClick={handleNavigate}
        >
          <img src={BackIocn} alt="backicon" />
          Back
        </p>
        <div className="flex flex-col gap-4 border border-gray-300 rounded-lg w-full shadow-lg p-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <h1 className="text-xl font-bold ">Essay Title :</h1>
              <p className="bg-gray-50 font-[500] text-[16px] p-2 rounded-lg">
                {evaluations?.submission_context?.essay_title}
              </p>
            </div>
            {state?.status === 'evaluated'?(
              ''
            ):
            <div className="flex gap-2">
              {/* <button
                onClick={handleEditOpen}
                className="p-2 rounded-lg border border-gray-300 bg-[#1da1f2] text-white font-[500] "
              >
                Edit Evaluation
              </button> */}
              <button
                onClick={handleApproveEvaluation}
                className="p-2 rounded-lg border border-gray-300 bg-green-800 text-white font-[500]"
              >
                Approve Evaluation
              </button>
            </div>
}
          </div>
          <div className="p-6 bg-gray-50 rounded-lg">
            <h2 className="text-xl font-semibold mb-4">
              Evaluation of the Student Essay
            </h2>

            <div
            dangerouslySetInnerHTML={{ __html: evaluations?.evaluation?.html_evaluation }}
            ></div>

          </div>
          {state?.status === 'evaluated' ?(
          ''
          ):(
            <div>
            <EditEvaluations  EditEvaluation={EditEvaluation} EditAiEvaluation={handleEditAiEvaluation} handleEvaluationChange={handleEvaluationChange}/>
          </div>
          )}
        </div>
      </div>
      {/* {editOpen && (
        <div
          class="  fixed inset-0 z-50 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div class="relative transform overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className=" max-w-xl mx-auto p-4">
                  <h2 className="flex justify-start border-b border-gray-200 text-2xl font-bold mb-4  text-center">
                    Essay Evaluation
                  </h2>
                  <div className="space-y-4">
                    <div className="flex flex-col gap-2">
                      <div className="w-full">
                        <label
                          className=" text-lg flex justify-start font-semibold mb-2"
                          htmlFor="className"
                        >
                          Score
                        </label>
                        <input
                          value={score}
                          onChange={handleScoreChange}
                          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500"
                          placeholder="Enter Score"
                          required
                        />
                      </div>
                      <div className="w-full">
                        <label
                          className=" text-lg flex justify-start font-semibold mb-2"
                          htmlFor="className"
                        >
                          Feedback
                        </label>
                        <textarea
                          type="text"
                          multiple
                          value={TeacherCommints}
                          onChange={handleCommitChange}
                          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500"
                          placeholder="Enter feedback"
                          required
                        />
                      </div>
                    </div>

                    <div className="text-center flex gap-2 justify-end w-full">
                      <button
                        onClick={handleEditClose}
                        className="px-6 py-1 border border-gray-500 text-[#000] rounded-lg  font-semibold"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleApproveEvaluation}
                        className="px-6 py-1 bg-green-800 text-white rounded-lg hover:bg-green-800 font-semibold"
                      >
                        Approve
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default AssignmentEvaluation;
