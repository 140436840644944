import React from 'react';

const RubricTable = ( {aigenEssayRubric}) => {
  console.log("🚀 ~ aigenEssayRubric ~ titles:", aigenEssayRubric)
//   const hasBoardOrYear = title?.some(item => item?.board || item?.year);
  
  return (

    <div className="bg-white  mb-4 shadow rounded-lg ">
                <h1 className='text-[24px] text-gray-700 font-[700] p-3 '>Marking Rubric for :<span className='text-[#070207] text-[28px] font-bold'>{` ${aigenEssayRubric?.essayTitle || ''}`}</span></h1>
           {aigenEssayRubric?.rubricDetails?.length > 0 ? (
            <div className='relative overflow-hidden'>
              <div className='dashboard-sidebar max-h-[400px] overflow-y-auto'> 
            <table className="min-w-full  relative">
              <thead  className='sticky top-0 bg-white z-10'>
                <tr className="border ">
                  <th className="  py-2 px-4 text-left">Criteria</th>
                    <th className=" py-2 px-4 text-left">Poor(1)</th>
                    <th className="py-2 px-4 text-left">Needs Improvement(2)</th>
                  <th className="py-2 px-4 text-left">Satisfactory(3)</th>
                  <th className="py-2 px-4 text-left">Good(4)</th>
                  <th className="py-2 px-4 text-left">Excellent(5)</th>

                </tr>
              </thead>
              <tbody >
                {aigenEssayRubric?.rubricDetails?.map((rubric, index) => (
                  <tr key={index} className="hover:bg-gray-100 cursor-pointer border">
                    <td className="py-3 px-4">{rubric?.criteria}</td>
                    <td className="py-3 px-4">{rubric?.Poor}</td>
                    <td className="py-3 px-4">{rubric?.Needs_Improvement}  </td>
                    <td className="py-3 px-4">{rubric?.Satisfactory}</td>
                    <td className="py-3 px-4">{rubric?.Good}</td>
                    <td className="py-3 px-4">{rubric?.Excellent}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
            </div>
          ) : (
            <p className="text-gray-500 text-center py-4">No Rubric found</p>
          )}
          </div>


      
      
    
  );
};

export default RubricTable;
